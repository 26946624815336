@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

.rowC {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
}

.color-nav {
	background-color: rgb(255,255,255);
	border-bottom: 2px solid #adb5bd;
}

.hrline {
	border: 3px black solid
}

.rowHeader {
	display:flex;
	flex-direction:row;
	align-items: flex-end;
	justify-content: space-between;
}

input[type="file"] {
	display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
